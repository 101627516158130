<template>
  <!-- v-show="yincheng" -->
  <div id="surveyform" v-show="yincheng">
    <div class="survey_conent">
      <div class="survey_header">
        <div class="survey_header_conent">
          <div class="surey_thou">
            <img class="survey_img" src="../assets/title_txt.png" />
          </div>
          <div class="header_text">
            尊敬的学员：
            <br />为了更好的对咱们企业进行服务，我们会认真地阅读每一份调研表，以在课前了解您的行业和公司，并在课程现场及学习过程中给您更多的建议
            ，最大限度帮助您在课程种收获更多，更快落地，请您务必<span
              class="header_text_span"
              >认真/如实</span
            >填写
          </div>
        </div>
      </div>

      <!-- 公司基本情况 -->
      <div class="survey_company">
        <img class="survey_company_img" src="../assets/01@2x.png" />
        <div class="survey_company_center">
          <p class="company_p"><span style="color: #ff0000">*</span>公司名称</p>
          <div>
            <!-- <el-input
              v-model="arrsdata.company_basic_info.enterprise_name"
              placeholder="请输入"
            ></el-input> -->

            <el-select
              style="width: 100%"
              v-bind="{
                clearable: true,
                'reserve-keyword': true,
                'collapse-tags': true,
                filterable: true,
                remote: true,
                'remote-method': searchRemotes,
                placeholder: '请输入公司名称',
              }"
              ref="agentSelect"
              v-selectLoadore="selectLoadMore"
              v-model="arrsdata.company_basic_info.enterprise_name"
              @hook:mounted="cancalReadOnly"
              @visible-change="cancalReadOnly"
            >
              <el-option
                v-for="(item, index) in hotOpts1"
                :key="item.index"
                :label="item.companyName"
                :value="item.companyName"
              >
                <div @click="locationChange(item, index)">
                  {{ item.companyName }}
                </div>
              </el-option>
            </el-select>
          </div>
          <!-- 法人/老板[必填] -->
          <div>
            <p class="company_p">
              <span style="color: #ff0000">*</span>法人/老板[必填]
            </p>
            <div class="contact">
              <div class="contact_text">法人姓名:</div>
              <el-input
                v-model="arrsdata.company_basic_info.corporate.corporate_name"
                placeholder="请输入"
              >
              </el-input>
            </div>
            <div class="contact">
              <div class="contact_text">联系电话:</div>
              <el-input
                v-model="arrsdata.company_basic_info.corporate.corporate_mobile"
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="contact">
              <div class="contact_text">微 信:</div>
              <el-input
                v-model="arrsdata.company_basic_info.corporate.corporate_wechat"
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="contact">
              <div class="contact_text">Email：</div>
              <el-input
                v-model="arrsdata.company_basic_info.corporate.corporate_email"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <!-- 本次学习直接联系人[必填] -->
          <div>
            <p class="company_p">
              <span style="color: #ff0000">*</span>本次学习直接联系人[必填]
            </p>
            <div class="contact">
              <div class="contact_text">联系人姓名:</div>
              <el-input
                v-model="
                  arrsdata.company_basic_info.direct_contact.direct_contact_name
                "
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="contact">
              <div class="contact_text">联系电话:</div>
              <el-input
                v-model="
                  arrsdata.company_basic_info.direct_contact
                    .direct_contact_mobile
                "
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="contact">
              <div class="contact_text">微 信:</div>
              <el-input
                v-model="
                  arrsdata.company_basic_info.direct_contact
                    .direct_contact_wechat
                "
                placeholder="请输入"
              ></el-input>
            </div>
            <div class="contact">
              <div class="contact_text">Email：</div>
              <el-input
                v-model="
                  arrsdata.company_basic_info.direct_contact
                    .direct_contact_email
                "
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <!-- 基础资料 -->
          <div>
            <p class="company_p">
              <span style="color: #ff0000"></span>基础资料
            </p>
            <div class="contact_chen contact_chens">
              <div class="contact1">
                <div class="contact_text1">
                  <span style="color: #ff0000">*</span>公司简介(必填)
                </div>
                <el-input
                  type="textarea"
                  placeholder="请输入"
                  v-model="
                    arrsdata.company_basic_info.basic_info.company_profile
                  "
                  maxlength="200"
                  show-word-limit
                  resize="none"
                ></el-input>
              </div>
            </div>
            <div class="contact_chen">
              <div class="contact1">
                <div class="contact_text1">
                  公司广告语/产品广告语(请提供3条左右)
                </div>
                <el-input
                  v-model="
                    arrsdata.company_basic_info.basic_info.corporate_advertising
                      .advertising_one
                  "
                  placeholder="请输入"
                ></el-input>
                <el-input
                  v-model="
                    arrsdata.company_basic_info.basic_info.corporate_advertising
                      .advertising_two
                  "
                  placeholder="请输入"
                ></el-input>
                <el-input
                  v-model="
                    arrsdata.company_basic_info.basic_info.corporate_advertising
                      .advertising_three
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>

            <div class="contact_chengai">
              <div class="contact1">
                <div
                  class="contact_text1"
                  style="display: flex; justify-content: space-between"
                >
                  <div>公司用于短视频推广的产品</div>
                  <div style="color: #bb7eff" @click="addproducts">
                    +增加产品
                  </div>
                </div>
                <div
                  class=""
                  v-for="(item, index) in arrsdata.company_basic_info.basic_info
                    .video_promotion_service"
                  :key="index"
                >
                  <el-input v-model="item.info" placeholder="请输入"></el-input>
                </div>
              </div>
            </div>

            <div class="contact_chen contaact_chenget">
              <div class="contact1">
                <div class="contact_text1">公司销售额</div>
                <div class="contact_flex">
                  <div class="contact_flexcont">
                    <div class="text">上年销售额(万)</div>
                    <div>
                      <el-input
                        v-model="
                          arrsdata.company_basic_info.basic_info.company_sales
                            .sales_last_year
                        "
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>

                  <div class="contact_flexcont">
                    <div class="text">上季度销售额(万)</div>
                    <div>
                      <el-input
                        v-model="
                          arrsdata.company_basic_info.basic_info.company_sales
                            .last_quarter_sales
                        "
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>
                </div>

                <div class="contact_text1">人员数量</div>
                <div class="contact_flex">
                  <div class="contact_flexcont">
                    <div class="text">公司人员总数量</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.company_basic_info.basic_info
                            .number_personnel.number_company_personnel
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in totalnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="contact_flexcont">
                    <div class="text">营销类人员数量</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.company_basic_info.basic_info
                            .number_personnel.number_marketing_personnel
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 分段 -->
        </div>
      </div>
      <!-- 用户基本情况 -->
      <div class="usersituation survey_company gongon">
        <img class="survey_company_img" src="../assets/02@2x.png" />
        <div class="survey_company_center">
          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >使用我们产品的用户是谁，用户有什么共同特征？[必填]<br /><span
              style="color: #999999"
              >(谁从我们这里买走产品，如经销商、代理商、个体户、直接客户。请按销售额排序列出）</span
            >
          </div>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="arrsdata.basic_user_info.who_features"
            show-word-limit
            resize="none"
          ></el-input>

          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >用户更关注我们产品的哪些功能和服务？[必填]<br /><span
              style="color: #999999"
            ></span>
          </div>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="arrsdata.basic_user_info.follow_func_service"
            show-word-limit
            resize="none"
          ></el-input>

          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >我们的产品或服务帮用户解决了什么问题？我们是如何解决的？[必填]<br /><span
              style="color: #999999"
              >(如：是不是厂家、生产规模、产品的质量、售后、利润空间等，限制在4点以内)</span
            >
          </div>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="arrsdata.basic_user_info.problem_solving"
            show-word-limit
            resize="none"
          ></el-input>
        </div>
      </div>
      <!-- 核心需求 -->
      <div class="coreneeds survey_company">
        <img class="survey_company_img" src="../assets/03@2x.png" />
        <div class="survey_company_center">
          <div class="coreneeds_cent">
            <div class="contact_text1 contact_top">
              <span style="color: #ff0000">*</span>已运营抖音账号
            </div>
            <div class="contact_chen">
              <div class="contact1 huan">
                <div class="contact_text1 dai">抖音名字</div>
                <el-input
                  v-model="
                    arrsdata.core_requirements.operation_tiktok_number
                      .tiktok_name
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
              <div class="contact1 huan">
                <div class="contact_text1 dai">抖音号</div>
                <el-input
                  v-model="
                    arrsdata.core_requirements.operation_tiktok_number
                      .tiktok_number
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
              <div class="contact1 huan">
                <div class="contact_text1 dai">账号描述</div>
                <el-input
                  type="textarea"
                  placeholder="请输入"
                  v-model="
                    arrsdata.core_requirements.operation_tiktok_number
                      .tiktok_info
                  "
                  show-word-limit
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="coreneeds_cent2">
            <div class="contact_text1 contact_top">
              <span style="color: #ff0000">*</span>目前抖音运营情况
            </div>
            <div class="contact_chen">
              <div class="contact1 huan">
                <div class="contact_text1 dai">粉丝数(人)</div>
                <el-input
                  v-model="
                    arrsdata.core_requirements.current_tiktok_operation.fans
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
              <div class="contact1 huan">
                <div class="contact_text1 dai">询盘量(天)</div>
                <el-input
                  v-model="
                    arrsdata.core_requirements.current_tiktok_operation
                      .inquiry_volume
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
              <div class="contact1 huan">
                <div class="contact_text1 dai">销售量(万)</div>
                <el-input
                  v-model="
                    arrsdata.core_requirements.current_tiktok_operation
                      .sales_volume
                  "
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </div>

          <div class="coreneeds_cent1">
            <div class="contact_chen">
              <div class="contact1 huan1">
                <div class="contact_text1 contact_top">
                  <span style="color: #ff0000">*</span>抖音运营遇到的难点
                </div>
                <el-input
                  type="textarea"
                  placeholder="请输入"
                  v-model="
                    arrsdata.core_requirements.tiktok_operation_difficulties
                  "
                  show-word-limit
                  resize="none"
                ></el-input>
              </div>
              <div class="contact1 huan1">
                <div class="contact_text1 contact_top">
                  <span style="color: #ff0000">*</span>做短视频的核心目标
                </div>
                <el-input
                  type="textarea"
                  placeholder="请输入"
                  v-model="arrsdata.core_requirements.video_core_target"
                  show-word-limit
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <!-- 团体情况 -->
      <div class="teamsituation survey_company">
        <img class="survey_company_img" src="../assets/04@2x.png" />
        <div class="survey_company_center">
          <div class="coreneeds_cent">
            <div class="contact_text1 contact_top">
              <span style="color: #ff0000">*</span
              >您公司是否有会短视频拍摄制作，运营及客服人员？
            </div>
            <div class="contact_chen contact_chengaibian">
              <div class="contact1">
                <div class="contact_flex">
                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">拍摄制作</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.whether_operate_service
                            .shooting_production
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">运营</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.whether_operate_service
                            .operate
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">客服</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.whether_operate_service
                            .customer_service
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="contact_text1 contact_top">
              <span style="color: #ff0000">*</span
              >如暂时没有上述人员，后期可以安排几人？
            </div>
            <div class="contact_chen contact_chengaibian">
              <div class="contact1">
                <div class="contact_flex">
                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">拍摄制作</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.not_operate_service
                            .shooting_production
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">运营</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.not_operate_service.operate
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="contact_flexcont contact_flexcont1">
                    <div class="text">客服</div>
                    <div>
                      <el-select
                        style="width: 100%"
                        v-model="
                          arrsdata.team_situation.not_operate_service
                            .customer_service
                        "
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in marketingnumbers"
                          :key="index"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="contact_text1 contact_top">
              <span style="color: #ff0000">*</span>可出镜人员的基本情况
            </div>
            <div
              v-for="(item, index) in arrsdata.team_situation
                .outbound_personnel_info"
              :key="index"
            >
              <div
                class="contact_text1"
                style="display: flex; justify-content: space-between"
              >
                <div style="font-weight: 300">出镜人员{{ index + 1 }}</div>
                <div style="color: #bb7eff" @click="addoutbound">+增加产品</div>
              </div>
              <div class="contact_chen contact_chengaibian1">
                <div class="contact1">
                  <!-- 人员分割 -->
                  <div class="contact_flex">
                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">姓名</div>
                      <div>
                        <el-input
                          v-model="item.name"
                          placeholder="请输入"
                        ></el-input>
                      </div>
                    </div>

                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">性别</div>
                      <div>
                        <el-select
                          style="width: 100%"
                          v-model="item.gender"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="(item, index) in teamsituation.actor1
                              .genderchent"
                            :key="index"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">年龄</div>
                      <div>
                        <el-select
                          style="width: 100%"
                          v-model="item.age"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="(item, index) in group"
                            :key="index"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>

                  <div class="contact_flex">
                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">性格</div>
                      <div>
                        <el-input
                          v-model="item.character"
                          placeholder="请输入"
                        ></el-input>
                      </div>
                    </div>

                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">爱好/特长</div>
                      <div>
                        <el-input
                          v-model="item.hobbies_specialties"
                          placeholder="请输入"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div class="contact_flexcont contact_flexcont1">
                      <div class="text">标签</div>
                      <div>
                        <el-input
                          v-model="item.label"
                          placeholder="请输入"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 分段 -->
          </div>
        </div>
      </div>
      <!-- 竞争对手 -->
      <div class="usersituations survey_company gongon">
        <img class="survey_company_img" src="../assets/05@2x.png" />
        <div class="survey_company_center">
          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >您行业主要竞争品牌有哪些？竞争对手在短视频方面表现如何？<br />
          </div>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="arrsdata.competitor.competitive_brand_performance"
            show-word-limit
            resize="none"
          >
          </el-input>

          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >您心目中做得比较好的短视频账号有哪些？<br />
          </div>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="arrsdata.competitor.good_short_video_number"
            show-word-limit
            resize="none"
          ></el-input>
        </div>
      </div>
      <!-- 参课人员安排 -->
      <div class="teamsituationaa survey_company">
        <img class="survey_company_img" src="../assets/06@2x.png" />
        <div class="survey_company_center">
          <div class="coreneeds_cent"></div>
          <div class="contact_text1 contact_top">
            <span style="color: #ff0000">*</span
            >本次参加《企业抖音盈利落地方案班人员信息》
          </div>
          <div
            v-for="(item, index) in arrsdata.arrangement_participants"
            :key="index"
          >
            <div
              class="contact_text1"
              style="display: flex; justify-content: space-between"
            >
              <div style="font-weight: 300">参课人员{{ index + 1 }}</div>
              <div style="color: #bb7eff" @click="addstaff">+增加人员</div>
            </div>
            <div class="contact_chen contact_chengaibian2">
              <div class="contact1">
                <!-- 人员分割 -->
                <div class="contact_flex">
                  <div class="contact_flexcont contact_flexcont2">
                    <div class="text">姓名</div>
                    <div>
                      <el-input
                        v-model="item.name"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>

                  <div class="contact_flexcont contact_flexcont2">
                    <div class="text">职务</div>
                    <div>
                      <el-input
                        v-model="item.post"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>
                  <div class="contact_flexcont contact_flexcont2">
                    <div class="text">联系方式</div>
                    <div>
                      <el-input
                        v-model="item.contact_information"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>
                  <div class="contact_flexcont contact_flexcont2">
                    <div class="text">身份证号</div>
                    <div>
                      <el-input
                        v-model="item.id_number"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="dibu">
      <div class="anniu" @click="submit">提交</div>
    </div>

    <div class="qrcode" v-if="rqcode">
      <div class="qrco"><img width="100%" height="100%" :src="rqcode" /></div>
      <div class="text">扫码提交</div>
    </div>

    <div class="cheng" v-show="cheng">
      <div class="gaidin">
        <img src="../assets/finish.png" />
        <div class="din">
          亲爱的学员~<br />
          该调研表已停止收集啦
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
      total_page: "", //公司总页数
      hotOpts1: [], //公司名称数据
      number: 1, //获取主关键词页数
      addproduct: 1, //短视频推广增加
      product: "", //短视频推广的产品
      product2: "", //短视频推广的产品增加1
      product3: "", //短视频推广的产品增加2
      lastyear: "", //上年销售额
      saleslast: "", //上季度销售额
      totalnumber: "", //公司人员总数量
      marketingnumber: "", //营销类人员数量
      totalnumbers: [], //公司人员总数量
      marketingnumbers: [], //营销类人员数量
      group: [], //年龄
      advertising2: "",
      advertising3: "",
      cheng: false,
      //用户基本情况
      situation: {
        usersituation: "",
        usersituation2: "",
        usersituation3: "",
      },
      // 核心需求
      coreneeds: {
        douyinname: "", //抖音名称
        douyin: "", //抖音号
        accountdescription: "", //账号描述
        numberoffans: "", //粉丝数
        inquiryvolume: "", //询盘量(/天)
        salesvolume: "", //销售量(/天)
        operation: "", // 抖音运营遇到的难点
        thecoregoal: "", //做短视频的核心目标
      },
      // 团队情况
      teamsituation: {
        part1: {
          production: "", //拍摄制作
          shooting: [
            // { value: "选项1", label: "黄金糕" },
            // { value: "选项1", label: "黄金糕" },
          ], //拍摄制作内容
          operation: "", //运营
          operationchent: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项1",
              label: "黄金糕",
            },
          ], //运营内容
          customerservice: "", //客服
          customerservicechent: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项1",
              label: "黄金糕",
            },
          ], //客服内容
        },
        part2: {
          production: "", //拍摄制作
          shooting: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项1",
              label: "黄金糕",
            },
          ], //拍摄制作内容
          operation: "", //运营
          operationchent: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项1",
              label: "黄金糕",
            },
          ], //运营内容
          customerservice: "", //客服
          customerservicechent: [
            {
              value: "选项1",
              label: "黄金糕",
            },
            {
              value: "选项1",
              label: "黄金糕",
            },
          ], //客服内容
        },
        //出镜人员1
        actor1: {
          name: "", //姓名
          gender: "", //性别
          genderchent: [
            {
              value: "",
              label: "男",
            },
            {
              value: "",
              label: "女",
            },
          ], //性别内容
          age: "", //年龄
          agechent: [
            {
              value: "",
              label: "女",
            },
            {
              value: "",
              label: "女",
            },
          ], //年龄内容
          character: "", //性格
          hobby: "", //爱好特长
          Label: "", //标签
          Labelcheent: [
            {
              value: "",
              label: "女",
            },
            {
              value: "",
              label: "女",
            },
          ], //标签内容
        },
      },
      // 竞争对手
      competitor: {
        competingbrands: "", //您行业主要竞争品牌
        Better: "", //您心目中做得比较好的短视频账号有哪些
      },
      //参选人员
      arrangement: {
        name: "", //姓名
        jobtitle: "", //职务
        contactdetails: "", //联系方式
        identitynumber: "", //身份证号
      },
      rqcode: "", //二维码
      msgs: "", //提示
      yincheng: true, //使用显示
      arrsdata: {
        company_basic_info: {
          enterprise_name: "", //公司名称
          corporate: {
            corporate_name: "", //法人名称
            corporate_mobile: "", //法人联系电话
            corporate_wechat: "", //法人微信
            corporate_email: "", //法人邮箱
          }, //法人信息
          direct_contact: {
            direct_contact_name: "", //本次学习直接联系人
            direct_contact_mobile: "", //本次学习直接联系人电话
            direct_contact_wechat: "", //本次学习直接联系人微信
            direct_contact_email: "", //本次学习直接联系人邮箱
          }, //本次学习直接联系人信息
          basic_info: {
            company_profile: "", //公司简介
            corporate_advertising: {
              advertising_one: "", //广告语1
              advertising_two: "", //广告语2
              advertising_three: "", //广告语3
            }, //公司广告语
            video_promotion_service: [
              {
                info: "", //公司用于短视频推广的产品服务
              },
            ], //公司用于短视频推广的产品服务
            company_sales: {
              sales_last_year: "", //上年销售额
              last_quarter_sales: "", //上季销售额
            }, //公司销售额
            number_personnel: {
              number_company_personnel: "", //公司人员数量
              number_marketing_personnel: "", //营销类人员数量
            }, //人员数量
          }, //基础资料
        }, //公司基本情况
        basic_user_info: {
          who_features: "", //使用我们产品的用户是谁,用户有什么特征
          follow_func_service: "", //用户关注我们产品的那些功能和服务
          problem_solving: "", //我们的产品或服务帮助用户解决什么问题,我们是如何解决的
        }, //用户基本情况
        core_requirements: {
          operation_tiktok_number: {
            tiktok_name: "", //抖音名字
            tiktok_number: "", //抖音号
            tiktok_info: "", //账号描述
          }, //已运营抖音号
          current_tiktok_operation: {
            fans: "", //粉丝数
            inquiry_volume: "", //询盘量
            sales_volume: "", //销售量
          }, //目前抖音运营情况
          tiktok_operation_difficulties: "", //抖音运营遇到难点
          video_core_target: "", //做短视频的核心目标
        }, //核心需求
        team_situation: {
          whether_operate_service: {
            shooting_production: "", //拍摄制作
            operate: "", //运营
            customer_service: "", //客服
          }, //你公司是否有会短视频拍摄制作,运营及客服人员
          not_operate_service: {
            shooting_production: "", //拍摄制作
            operate: "", //运营
            customer_service: "", //客服
          }, //如暂时没有上述人员,后期可以安排几人
          outbound_personnel_info: [
            {
              name: "", //姓名
              gender: "", //性别
              character: "", //性格
              age: "", //年龄
              hobbies_specialties: "", //爱好特长
              label: "", //标签
            },
          ], //可出境人员基本情况
        }, //团队情况
        competitor: {
          competitive_brand_performance: "", //你行业主要的竞争品牌有哪些?竞争对手在短视频方面表现如何
          good_short_video_number: "", //你心目中做的比较好的短视频账号有哪些
        }, //竞争对手
        arrangement_participants: [
          {
            name: "", //姓名
            post: "", //职务
            contact_information: "", //联系方式
            id_number: "", //身份证号
          },
        ], //参课人员安排
        sol_id: "", //调研发起表id
      }, //返回数据
    };
  },
  created() {
    const arrsdata = JSON.parse(
      window.localStorage.getItem("params-arrsdata") || "null"
    );
    if (arrsdata) this.arrsdata = arrsdata;
  },
  activated() {
    this.numberooptions();
    this.getNumberMas();
    this.getAgeGroup();
    this.getwhetherStopCollect();
    this.getQrcode();
  },
  watch: {
    arrsdata: {
      deep: true,
      handler(v) {
        window.localStorage.setItem("params-arrsdata", JSON.stringify(v));
      },
    },
  },
  //自定义下拉组件
  directives: {
    selectLoadore: {
      bind: (el, binding) => {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    //短视频推广的产品增加
    addproducts() {
      // this.addproduct++;
      this.arrsdata.company_basic_info.basic_info.video_promotion_service.push({
        info: "",
      });
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs;
          console.log(Selects); // 如果只有1个下拉框，这段就足够了---start
          if (Selects.agentSelect) {
            const input =
              Selects.agentSelect.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          } // 如果只有1个下拉框，这段就足够了---end // 如果有多个，就加多几个，代码可以优化，我懒了
          if (Selects.agent2Select) {
            const appinput =
              Selects.appSelect.$el.querySelector(".el-input__inner");
            appinput.removeAttribute("readonly");
          }
          if (Selects.agent3Select) {
            const gameinput =
              Selects.gameSelect.$el.querySelector(".el-input__inner");
            gameinput.removeAttribute("readonly");
          }
        }
      });
    },
    //公司选中时发送变化
    locationChange(item, index) {
      // this.poi_names = item.poi_name;
      // this.poi_ids = item.poi_id;
    },
    //获取公司信息
    searchRemotes(keyword) {
      // console.log(keyword);
      // console.log(this.arrsdata.company_basic_info.enterprise_name);
      this.hotOpts1 = [];
      this.number = 1;
      this.keyword = keyword;
      this.$api.user
        .queryOrgCompanyQuery({
          companyName: this.keyword,
          page: this.number,
          page_limit: 10,
        })
        .then((res) => {
          if (res && res.code === 1) this.hotOpts1 = res.data.data;
          this.total_page = res.data.total_page;
          console.log(this.hotOpts1);
          console.log(keyword, 11);
        });
    },
    //滚到底部
    selectLoadMore() {
      console.log(this.keyword);
      this.number = this.number + 1;
      if (this.number > this.total_page) return;
      // this.searchRemotes();
      this.$api.user
        .queryOrgCompanyQuery({
          companyName: this.keyword,
          page: this.number,
          page_limit: 10,
        })
        .then((res) => {
          if (res && res.code === 1)
            this.hotOpts1 = this.hotOpts1.concat(res.data.data);
          console.log(this.hotOpts1);
          console.log(keyword, 11);
        })
        .catch((ress) => {
          console.log("失败");
        });
    },
    //获取年龄段
    getAgeGroup() {
      this.$api.user.getAgeGroup().then((res) => {
        if (res.code == 1) {
          this.group = res.data.list;
          // console.log(this.group,);
        }
      });
    },
    // 获取调研表是否停止收集
    getwhetherStopCollect() {
      this.arrsdata.sol_id = this.$route.query.sol_id;
      let params = {
        sol_id: this.arrsdata.sol_id,
      };
      this.$api.user.getwhetherStopCollect(params).then((res) => {
        // this.msgs = res.msg;
        if (res.code == 1) {
          if (res.data.status == true) {
            this.cheng = false;
          } else {
            // this.$message({
            //   message: res.msg,
            //   type: "warning",
            // });
            this.cheng = true;
          }
        } else {
          this.yincheng = false;
        }
      });
    },

    //获取二维码
    getQrcode() {
      let params = {
        sol_id: this.arrsdata.sol_id,
      };
      this.$api.user.getQrcode(params).then((res) => {
        this.rqcode = res.data.qrcode;
        // console.log(res);
      });
    },
    //获取人员数量可选项
    numberooptions() {
      this.$api.user
        .getTotalNumber()
        .then((res) => {
          if (res.code == 1) {
            this.totalnumbers = res.data.list;
          }
        })
        .catch(function (error) {
          console.log("没成功");
          return;
        });
    },
    //添加出境人员
    addoutbound() {
      this.arrsdata.team_situation.outbound_personnel_info.push({
        name: "", //姓名
        gender: "", //性别
        character: "", //性格
        age: "", //年龄
        hobbies_specialties: "", //爱好特长
        label: "", //标签
      });
      console.log(this.arrsdata.team_situation.outbound_personnel_info);
    },
    addstaff() {
      this.arrsdata.arrangement_participants.push({
        name: "", //姓名
        post: "", //职务
        contact_information: "", //联系方式
        id_number: "", //身份证号
      });
    },
    // 获取营销类人员数量可选项
    getNumberMas() {
      this.$api.user
        .getNumberMa()
        .then((res) => {
          if (res.code == 1) {
            this.marketingnumbers = res.data.list;
          } else {
            console.log("失败");
          }
        })
        .catch(function (error) {
          console.log("没成功");
          return;
        });
    },
    submit() {
      if (!this.arrsdata.company_basic_info.corporate.corporate_name) {
        this.$message({
          message: "请填写法人/老板联 系 人",
          type: "warning",
        });
        return;
      }

      if (!this.arrsdata.company_basic_info.corporate.corporate_mobile) {
        this.$message({
          message: "请填写法人/老板联系电话",
          type: "warning",
        });
        return;
      }
      if (
        !/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(
          this.arrsdata.company_basic_info.corporate.corporate_mobile
        )
      ) {
        this.$message({
          message: "法人/老板/联系电话号码格式不正确",
          type: "warning",
        });
        return;
      }
      if (!this.arrsdata.company_basic_info.corporate.corporate_wechat) {
        this.$message({
          message: "请填写法人/老板微信",
          type: "warning",
        });
        return;
      }

      if (!this.arrsdata.company_basic_info.corporate.corporate_email) {
        this.$message({
          message: "请填写法人/老板邮箱",
          type: "warning",
        });
        return;
      }

      if (
        !/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(
          this.arrsdata.company_basic_info.corporate.corporate_email
        )
      ) {
        this.$message({
          message: "法人/老板邮箱格式不正确",
          type: "warning",
        });
        return false;
      }
      if (
        !this.arrsdata.company_basic_info.direct_contact.direct_contact_name
      ) {
        this.$message({
          message: "请填写本次学习直接联系人",
          type: "warning",
        });
        return;
      }
      if (
        !this.arrsdata.company_basic_info.direct_contact.direct_contact_mobile
      ) {
        this.$message({
          message: "请填写联系人电话",
          type: "warning",
        });
        return;
      }
      if (
        !/^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(
          this.arrsdata.company_basic_info.direct_contact.direct_contact_mobile
        )
      ) {
        this.$message({
          message: "联系人电话号码格式不正确",
          type: "warning",
        });
        return;
      }
      if (
        !this.arrsdata.company_basic_info.direct_contact.direct_contact_wechat
      ) {
        this.$message({
          message: "请填写本次学习直接联系人微信",
          type: "warning",
        });
        return;
      }
      if (
        !this.arrsdata.company_basic_info.direct_contact.direct_contact_email
      ) {
        this.$message({
          message: "请填写联系人邮箱",
          type: "warning",
        });
        return;
      }
      if (
        !/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(
          this.arrsdata.company_basic_info.direct_contact.direct_contact_email
        )
      ) {
        this.$message({
          message: "联系人邮箱格式不正确",
          type: "warning",
        });
        return false;
      }
      if (!this.arrsdata.company_basic_info.basic_info.company_profile) {
        this.$message({
          message: "请填写公司简介",
          type: "warning",
        });
        return;
      }
      if (!this.arrsdata.basic_user_info.who_features) {
        this.$message({
          message: "请填写使用我们产品的用户是谁，用户有什么共同特征",
          type: "warning",
        });
        return;
      }
      if (!this.arrsdata.basic_user_info.follow_func_service) {
        this.$message({
          message: "请填写用户关注我们产品的那些功能和服务",
          type: "warning",
        });
        return;
      }
      if (!this.arrsdata.basic_user_info.problem_solving) {
        this.$message({
          message:
            "请填写我们的产品或服务帮助用户解决什么问题,我们是如何解决的",
          type: "warning",
        });
        return;
      }
      console.log(this.arrsdata.arrangement_participants.name);

      let params = {
        data: this.arrsdata,
      };
      this.$api.user
        .addSurvey(params)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            this.$message({
              message: res.msg,
              type: "success",
            });
            // this.arrsdata = {};
            // this.cheng = true;
            window.localStorage.clear();
            location.reload();
          } else {
            console.log("失败");
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log("没成功");
          this.$message({
            message: error.msg,
            type: "warning",
          });
          return;
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
*,
body {
}

@import "./css/mobilephonedevice.scss";

@media only screen and (max-width: 768px) {
  @import "./css/desktop.scss";
}
</style>
